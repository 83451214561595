@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  margin: 0;
}

@media only screen and (min-width: 1024px) {
  body {
    background-color: #FBFBFB;
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 2000px) {
  body {
    background-color: #FBFBFB;
    overflow-x: hidden;
  }
}

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(17 24 39);
}

code {
  font-family: 'Rubik', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-background {
  background: var(--fondo-bicolor, linear-gradient(180deg, #FF516C 0%, #78E0E6 100%));
  color: white;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
}

.card {
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
  background-color: #ffffff;
}

.skeleton-card-color {
  background-color: gray !important;
}

.white-text-shadow {
  text-shadow: 0px 0px 10px #FFF;
}

.bg-yellow {
  background-color: #FFEF5D;
}

h1 {
  font-size: 1.25rem;
  font-weight: 500;
}

.event-colors {
  background-color: #FBFBFB;
  color: #101828;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/*Fonts*/
.display-2xl-regular,
.display-2xl-medium,
.display-xl-semibold,
.display-2xl-bold {
  font-size: 72px;
  line-height: 90px;
  letter-spacing: -1.44px;
}

.display-2xl-regular {
  font-weight: 400;
}

.display-2xl-medium {
  font-weight: 500;
}

.display-2xl-semibold {
  font-weight: 600;
}

.display-2xl-bold {
  font-weight: 700;
}

.display-xl-regular,
.display-xl-medium,
.display-xl-semibold,
.display-xl-bold {
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -1.2px;
}

.display-xl-regular {
  font-weight: 400;
}

.display-xl-medium {
  font-weight: 500;
}

.display-xl-semibold {
  font-weight: 600;
}

.display-xl-bold {
  font-weight: 700;
}

.display-lg-regular,
.display-lg-medium,
.display-lg-semibold,
.display-lg-bold {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.display-lg-regular {
  font-weight: 400;
}

.display-lg-medium {
  font-weight: 500;
}

.display-lg-semibold {
  font-weight: 600;
}

.display-lg-bold {
  font-weight: 700;
}

.display-md-regular,
.display-md-medium,
.display-md-semibold,
.display-md-bold {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.display-md-regular {
  font-weight: 400;
}

.display-md-medium {
  font-weight: 500;
}

.display-md-semibold {
  font-weight: 600;
}

.display-md-bold {
  font-weight: 700;
}

.display-sm-regular,
.display-sm-medium,
.display-sm-semibold,
.display-sm-bold {
  font-size: 30px;
  line-height: 38px;
}

.display-sm-regular {
  font-weight: 400;
}

.display-sm-medium {
  font-weight: 500;
}

.display-sm-semibold {
  font-weight: 600;
}

.display-sm-bold {
  font-weight: 700;
}

.display-xs-regular,
.display-xs-medium,
.display-xs-semibold,
.display-xs-bold {
  font-size: 24px;
  line-height: 32px;
}

.display-xs-regular {
  font-weight: 400;
}

.display-xs-medium {
  font-weight: 500;
}

.display-xs-semibold {
  font-weight: 600;
}

.display-xs-bold {
  font-weight: 700;
}

.text-xl-regular,
.text-xl-medium,
.text-xl-semibold,
.text-xl-bold {
  font-size: 20px;
  line-height: 30px;
}

.text-xl-regular {
  font-weight: 400;
}

.text-xl-medium {
  font-weight: 500;
}

.text-xl-semibold {
  font-weight: 600;
}

.text-xl-bold {
  font-weight: 700;
}

.text-lg-regular,
.text-lg-medium,
.text-lg-semibold,
.text-lg-bold {
  font-size: 18px;
  line-height: 28px;
}

.text-lg-regular {
  font-weight: 400;
}

.text-lg-medium {
  font-weight: 500;
}

.text-lg-semibold {
  font-weight: 600;
}

.text-lg-bold {
  font-weight: 700;
}

.text-md-regular,
.text-md-medium,
.text-md-semibold,
.text-md-bold {
  font-size: 16px;
  line-height: 24px;
}

.text-md-regular {
  font-weight: 400;
}

.text-md-medium {
  font-weight: 500;
}

.text-md-semibold {
  font-weight: 600;
}

.text-md-bold {
  font-weight: 700;
}

.text-sm-regular,
.text-sm-medium,
.text-sm-semibold,
.text-sm-bold {
  font-size: 14px;
  line-height: 20px;
}

.text-sm-regular {
  font-weight: 400;
}

.text-sm-medium {
  font-weight: 500;
}

.text-sm-semibold {
  font-weight: 600;
}

.text-sm-bold {
  font-weight: 700;
}

.text-xs-regular,
.text-xs-medium,
.text-xs-semibold,
.text-xs-bold {
  font-size: 12px;
  line-height: 18px;
}

.text-xs-regular {
  font-weight: 400;
}

.text-xs-medium {
  font-weight: 500;
}

.text-xs-semibold {
  font-weight: 600;
}

.text-xs-bold {
  font-weight: 700;
}
.shadow-xs{
  box-shadow: 0px 1px 2px 0px rgba(81, 82, 92, 0.05);
}
.shadow-sm{
  box-shadow: 0px 1px 3px 0px rgba(85, 17, 85, 0.17), 0px 1px 2px 0px rgba(81, 82, 92, 0.06);
}
.shadow-md{
  box-shadow: 0px 4px 8px -2px rgba(81, 82, 92, 0.10), 0px 2px 4px -2px rgba(85, 17, 85, 0.17);
}
.shadow-lg{
  box-shadow: 0px 12px 16px -4px rgba(81, 82, 92, 0.08), 0px 4px 6px -2px rgba(81, 82, 92, 0.03);
}
.shadow-xl{
  box-shadow: 0px 20px 24px -4px rgba(81, 82, 92, 0.08), 0px 8px 8px -4px rgba(81, 82, 92, 0.03);
}
.shdow-2xl{
  box-shadow: 0px 24px 48px -12px rgba(81, 82, 92, 0.18);
}
.shdow-3xl{
  box-shadow: 0px 32px 64px -12px rgba(81, 82, 92, 0.14);
}