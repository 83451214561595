.slick-prev,
.slick-next {
  width: 22px; 
  height: 28px;
  background-color: #3F3F46; 
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 10; 
}
.slick-prev:before,
.slick-next:before {
  font-size: 35px; 
  color: white;
  opacity: 1;
  margin-left: -6px;
}
.slick-prev:hover,
.slick-next:hover{
  background-color: #3F3F46;
}
.slick-prev:active,
.slick-next:active{
  background-color: #3F3F46;
}
.slick-prev:focus,
.slick-next:focus{
  background-color: #3F3F46;
}
.slick-next{
  right: -14px;
}
.slick-prev{
  left: -14px;
}
.slick-slide{
  max-height: 480px;
}
.slick-dots{
  visibility: hidden;
}
.slick-list{
  border-radius: 8px;
}
.h-carrousel{
  height: 480px;
}
@media only screen and (max-width: 930px) {
  .h-carrousel {
    height: 150px;
  }
  .border-radius{
    border-radius: 8px;
  }
}
