.MuiTableCell-root{
  border-bottom: 1px solid #E4E4E7!important;
  color: #51525C!important;
  font-family: 'Rubik'!important;
  font-size: 14px!important;
  line-height: 20px!important;
}
.MuiTableCell-head.MuiTableCell-root{
  background-color: #FAFAFA;
  font-weight: 500;
}

/* Add custom scrollbar styling */
.MuiTable-root {
  z-index: 50;
}
