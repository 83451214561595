.desktop{
  display: block;
}
.mobile{
  display: none;
}
@media only screen and (max-width: 930px) {
  .mobile{
    display: block;
  }
  .desktop{
    display: none;
  }
}